<template>
  <div class="content">
    <img
      src="../assets/imgs/teachersDay_app/teacher_1.png"
      mode="widthFix"
      @click="toIndex"
    />
    <img src="../assets/imgs/teachersDay_app/teacher_2.png" mode="widthFix" />
    <div class="banner1">
      <div class="swiper-container topBanner">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in 5" :key="item">
            <img
              :src="
                require('../assets/imgs/teachersDay_app/teacher_' +
                  (item + 16) +
                  '.jpg')
              "
            />
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <img src="../assets/imgs/teachersDay_app/teacher_4.png" mode="widthFix" />
    <div class="videoWrap">
      <video
        poster="https://xx.wzclzx.com/4a53ccb3451a54d90c290321036ba577"
        src="https://xx.wzclzx.com/883abb770b0b3660bb43ceaa1be4409b"
        autoplay
        loop
        controls
      ></video>
    </div>
    <img src="../assets/imgs/teachersDay_app/teacher_6.png" mode="widthFix" />
    <div class="banner2">
      <div class="item" v-for="item in list3" :key="item.id">
        <img mode="widthFix" :src="item.cover" />
        <div class="con">{{ item.content }}</div>
      </div>
    </div>
    <div class="banner3">
      <div class="mask">望子成龙庆祝第三十五个教师节合影留念</div>
      <div class="longSwiper">
        <img src="https://xx.wzclzx.com/90e3cea5b0ba4f8c674623e890ff2451" />
        <img src="https://xx.wzclzx.com/90e3cea5b0ba4f8c674623e890ff2451" />
      </div>
    </div>
    <img
      v-for="(item, index) in 9"
      :src="
        require('../assets/imgs/teachersDay_app/teacher_' +
          (index + 8) +
          '.png')
      "
      :key="index"
    />
  </div>
</template>

<script>
import Swiper from "swiper/dist/js/swiper.js";
export default {
  data() {
    return {
      list2: [
        {
          id: 0,
          num: "01",
          title: "董事长致辞",
        },
        {
          id: 1,
          num: "02",
          title: "袁明先生",
        },
        {
          id: 2,
          num: "03",
          title: "杨建德先生",
        },
        {
          id: 3,
          num: "04",
          title: "张天保先生",
        },
      ],
      list3: [
        {
          id: 0,
          cover: require("../assets/imgs/teachersDay_app/t8.jpg"),
          content: `
						庆祝活动在望子成龙教育集团董事长、
						成都玉垒中学校长蒋杨斌的讲话中正
						式拉开帷幕。他代表望子成龙教育集
						团，向辛勤耕耘、无私奉献的全体教
						职员工致以节日的问候和崇高的敬意！
						向指导、关心集团发展的各级领导、
						社会各界人士表示衷心的感谢！百年
						大计，教育为本。教育大计，教师为本。
						他鼓励老师誓做追梦人 ，甘为“大先生”，
						为践行现代教育强国，实现民族伟大复兴
						而不懈努力！`,
        },
        {
          id: 1,
          cover: require("../assets/imgs/teachersDay_app/t9.jpg"),
          content: `
						都江堰市人民政府副市长袁明先生，祝贺望子成龙
						教育集团旗下玉垒中学被评为都江堰市先进集体。`,
        },
        {
          id: 2,
          cover: require("../assets/imgs/teachersDay_app/t10.jpg"),
          content: "成都市政协副主席杨建德先生讲话。",
        },
        {
          id: 3,
          cover: require("../assets/imgs/teachersDay_app/t11.jpg"),
          content: "原教育部副部长、国家总督学顾问张天保先生讲话",
        },
      ],
      list4: [],
      //淡入淡出轮播图
      currentIndex: 0,
    };
  },
  mounted() {
    //淡入淡出轮播图
    this.list4 = [this.list3[0]];
    this.startChange();
    new Swiper(".topBanner", {
      loop: true,
      speed: 300,
      observer: true,
      grabCursor: true,
      updateOnWindowResize: true,
      autoplay: true,
      pagination: {
        el: ".swiper-pagination",
        bulletActiveClass: "top-banner-bullet-active",
      },
    });
  },
  methods: {
    toIndex() {
      location.href = "http://www.wzclxx.com/";
    },
    //淡入淡出轮播图
    startChange() {
      setInterval(() => {
        if (this.currentIndex < this.list3.length - 1) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0;
        }
        this.list4.splice(0, 1, this.list3[this.currentIndex]);
      }, 2000);
    },

    //点击左侧按钮显示对应的图片
    onClick(index) {
      this.currentIndex = index;
      this.list4.splice(0, 1, this.list3[this.currentIndex]);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;

  img {
    width: 100%;
    font-size: 0;
    display: block;
  }
}

.banner1 {
  height: 412rpx;
  padding: 0 8rpx;
  margin-bottom: 14rpx;
  overflow: hidden;

  img {
    width: 100%;
    height: 412rpx;
  }

  uni-swiper {
    height: 100%;
  }
}

.videoWrap {
  height: 455rpx;
  padding: 0 8rpx;
  video {
    width: 100%;
    height: 100%;
  }
}

//轮播
.banner2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 12rpx;
  .item {
    width: 100%;
    margin: 15px auto;
    img {
      width: 100%;
    }
    .con {
      font-size: 10px;
      line-height: 24px;
      margin-top: 10px;
      color: #666666;
    }
  }
}

//淡入淡出轮播图
.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 1s;
}

.flip-list-enter,
.flip-list-leave-active {
  opacity: 0;
}

.banner3 {
  height: 340rpx;
  margin-top: 32rpx;
  overflow: hidden;
  position: relative;
  .mask {
    width: 100%;
    height: 52rpx;
    line-height: 52rpx;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 24rpx;
    text-align: center;
    z-index: 10;
  }
  .longSwiper {
    width: 100%;
    display: flex;
    animation: 80s rowup linear infinite normal;
    img {
      width: 4821rpx;
      height: 342rpx;
      flex-shrink: 0;
    }
  }

  @keyframes rowup {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-4821rpx));
    }
  }
}
</style>
